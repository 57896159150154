@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-spinner {
  border-top-color: #4775f2;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
